import React, { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Goback from "../assets/image/GoBack.svg";
import DELETE from "../assets/image/Delete.svg";
import Add from "../assets/image/Add.svg";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import ProductHeader from "./ProductHeader";
import InputField from "./InputField";
import { customer } from "../redux/reducer/handleCart";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { getCustomerRegister, headOfficeName } from "../api/GlobalApi";
import showIcon from "../assets/image/pasShow.png";
import hiddenIcon from "../assets/image/pashidden.png";
import AlertModal from "./AlertModal";
const CustomerRegister = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [base64Image, setBase64Image] = useState(null);
  const [errorPassword, setErrorPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  console.log("errorOpen", errorOpen);

  const customerLoginValue = sessionStorage.getItem("login");
  const handleImageInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        // const retun = base64String.replace("data:image/jpeg;base64," , " ");
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(",") + 1
        );
        setBase64Image(base64LetterRemove);
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };
  // console.log("State", state);
  const getHeadOffice = useQuery("headOfficeName", headOfficeName);
  let headOfficeList =
    getHeadOffice && getHeadOffice?.data && getHeadOffice?.data?.data
      ? getHeadOffice && getHeadOffice?.data && getHeadOffice?.data?.data
      : [];
  const { mutate, isSuccess, isLoading } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/companies/createCompany`,
        postData
      ),
    {
      onSuccess: (data) => {
        console.log("some_value", data);
        // alert("customer added successfully");
        setOpen("Customer profile has been successfully added.");
        // reset();
        // if (customerLoginValue) {
        //   navigate("/UserRegisterTable");
        // } else {
        //   navigate("/");
        // }
      },
      onError: (error) => {
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
        console.log("Mutation failed:");
      },
    }
  );
  const updata = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/companies/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // Handle successful mutation
        // alert("Update successful!");
        // reset();
        // navigate("/UserRegisterTable");
        setUpdateOpen("Customer profile has been successfully updates.");
      },
      onError: (error) => {
        // Handle error
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
        console.error("Mutation failed:", error);
      },
    }
  );
  let base64String = state && state?.companyLogo;
  console.log("state", state);
  const GetcustomerRegister = useQuery(
    "customerResisterTable",
    getCustomerRegister
  );
  let details =
    GetcustomerRegister &&
    GetcustomerRegister?.data &&
    GetcustomerRegister?.data?.data
      ? GetcustomerRegister &&
        GetcustomerRegister?.data &&
        GetcustomerRegister?.data?.data
      : [];
  const headOffice = details?.filter((item) => {
    return item?.companyType === "headOffice";
  });

  useEffect(() => {
    setValue("customerName", state?.customerName);
    setValue("customerPhoneNumber", state?.customerPhno);
    setValue("customerAltPhoneNumber", state?.customeralternativePhno);
    setValue("shopName", state?.shopName);
    setValue("companyAddress", state?.companyAddress);
    setValue("customerPinCode", state?.companyPincode);
    setValue("companyLogo", state?.companyLogo);
    setValue("companyType", state?.companyType);
    setValue("gstNumber", state?.gstNumber);
    setValue("website", state?.website);
    setValue("email", state?.emailId);
    setValue("password", state?.password);
    setValue("confirmPassword", state?.confirmPassword);
    setValue("role", state?.role);
    setValue("status", state?.status);
    setValue("selectHeadOffice", state?.headoffice);
    setValue("district", state?.district);
  }, []);
  const { password, confirmPassword, companyType } = watch();
  const onSubmit = (data) => {
    console.log("data", data);

    const value = {
      customerName: data.customerName,
      shopName: data.shopName,
      customerPhno: data.customerPhoneNumber,
      customeralternativePhno: data.customerAltPhoneNumber,
      companyAddress: data.companyAddress,
      companyLogo:
        base64Image === null ? state && state?.companyLogo : base64Image,
      // companyLocation: "City",
      companyPincode: data.customerPinCode,
      companyType: data.companyType,
      emailId: data.email,
      website: data.website,
      gstNumber: data.gstNumber,
      role: data.role,
      status: adminuser !== "superAdmin" ? "inactive" : data?.status,
      password: data.password,
      confirmpassword: data.confirmPassword,
      headoffice: data.selectHeadOffice,
      district: data?.district,
    };
    if (password !== confirmPassword) {
      setErrorPassword(true);
    } else if (state && state.id) {
      updata.mutate(value);
      console.log("edit", value);
    } else {
      mutate(value);
      console.log("add", value);
    }
    console.log("value", value);
    // dispatch(customer({ value }));
  };
  const errorSumbit = () => {
    console.log("password", password);
  };
  const adminuser = sessionStorage.getItem("user");
  console.log("adminuser", adminuser);

  console.log("count_login", base64String);
  const [isImages, setIsImages] = useState(state && state.companyLogo);
  const handlerImage = () => {
    const confirmDelete = window.confirm("Are you want to Change the Image?");
    if (confirmDelete) {
      setIsImages(false);
    }
  };
  const [isShow, setIsShow] = useState(true);
  const [isPass, setIsPass] = useState(true);

  return (
    <>
      <div className="main-container">
        <ProductHeader back={true} label="Add Customer" />
        <div className="BillingAddMainBlock">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="PurchaseAddFormBlock"
          >
            <div>
              <div
                className="BillingAddInputContainer"
                style={{ justifyContent: "", width: "100%" }}
              >
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Customer Name{" "}
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="customerName"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="Customer Name"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    {" "}
                    Phone Number{" "}
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="customerPhoneNumber"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="Phone Number"
                        type="number"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Alt Phone Number</label>
                  <Controller
                    control={control}
                    name="customerAltPhoneNumber"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="Alt Phone Number"
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Company type
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="companyType"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="mt-2 text-capitalize billing_input_field"
                        // customerInputCss="--customerInputCss"
                        style={error ? { borderColor: "red" } : {}}
                      >
                        <option value=""> </option>
                        <option value="headOffice">Head Office</option>

                        <option value="subOffice">Branch Office</option>
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {companyType === "subOffice" ? (
                  <div className="BillingAddInputField ">
                    <label className="ProductLabel">
                      Select Your Head Office
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="selectHeadOffice"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          onChange={onChange}
                          className="mt-2  text-capitalize billing_input_field"
                          // customerInputCss="--customerInputCss"
                          // style={ errors.selectHeadOffice ? { borderColor: "red" }
                          // : {} }
                        >
                          <option value=" " selected>
                            {" "}
                          </option>
                          {headOffice?.map((data, index) => (
                            <option key={index} value={data?.shopName}>
                              {data.shopName}
                            </option>
                          ))}
                          {/* <option value="Head Office">Head Office</option>
                          <option value="Sub Office">Sub Office</option> */}
                        </select>
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {companyType ? (
                  <div className="BillingAddInputField ">
                    <label className="ProductLabel">
                      {companyType === "headOffice"
                        ? "Head Office Name"
                        : "Branch Office Name"}{" "}
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="shopName"
                      render={(field) => (
                        <InputField
                          customerInputCss="billing_input_field"
                          {...field}
                          // placeholder="Customer Name"
                          type="text"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {companyType ? (
                  <div className="BillingAddInputField ">
                    <label className="ProductLabel">
                      {" "}
                      {companyType === "headOffice"
                        ? "Head Office Address"
                        : "Branch Office Address"}{" "}
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="companyAddress"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <textarea
                          // style={ errors.companyAddress ? { borderColor: "red" }
                          //   : {} }
                          value={value}
                          onChange={onChange}
                          // {...field}
                          className="mt-2 billing_input_field"
                        ></textarea>
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    District
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="district"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="Customer Pin Code"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Customer Pin Code
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="customerPinCode"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="Customer Pin Code"
                        type="number"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="BillingAddInputField">
                  <label className="ProductLabel">
                    Company Logo
                    {/* <span style={{ color: "red" ,fontSize:"14px" }}>*</span> */}
                  </label>
                  {isImages ? (
                    <p
                      className=" mt-2 billing_input_field "
                      onClick={() => handlerImage()}
                    >
                      {base64String
                        ?.substring(5, base64String.indexOf(";"))
                        .replace("/", ".")}
                    </p>
                  ) : (
                    <Controller
                      control={control}
                      name="companyLogo"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          // style={ base64String ? { borderColor: "red" }
                          //   : {} }
                          // style={{ paddingTop: "5px", paddingLeft: "6px" }}
                          className="mt-2 pt-1 ps-1 billing_input_field "
                          accept="image/*"
                          type="file"
                          onChange={(e) => onChange(handleImageInputChange(e))}
                        />
                        // <InputField  {...field} placeholder="0.00 " type="file" />
                      )}
                      // rules={{ required: true }}
                    />
                  )}
                </div>

                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    GST Number
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="gstNumber"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="GST Number"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    Website
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="website"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="Website"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">
                    E-Mail
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="email"
                    render={(field) => (
                      <InputField
                        customerInputCss="billing_input_field"
                        {...field}
                        // placeholder="E-Mail"
                        type="e-mail"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="ProductLabel">Password</label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Controller
                      control={control}
                      name="password"
                      render={(field) => (
                        <InputField
                          customerInputCss="billing_input_field"
                          {...field}
                          // placeholder="Password"
                          type={`${!isPass ? "text" : "password"}`}
                        />
                      )}
                    />
                    {/* {
                    !isPass?<img onClick={()=>setIsPass(!isPass)}  src={showIcon} alt="Show"style={{width:'25px'}}/>:
                    <img onClick={()=>setIsPass(!isPass)} src={hiddenIcon} alt="Show" style={{width:'25px'}}/>
                  } */}
                  </div>
                </div>
                <div className="me-0 BillingAddInputField ">
                  <label className="ProductLabel">Confirm Password</label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Controller
                      control={control}
                      name="confirmPassword"
                      render={(field) => (
                        <InputField
                          customerInputCss=" billing_input_field"
                          {...field}
                          // placeholder="Confirm Password"
                          type={`${!isShow ? "text" : "password"}`}
                        />
                      )}
                    />
                    {!isShow ? (
                      <img
                        onClick={() => setIsShow(!isShow)}
                        src={showIcon}
                        alt="Show"
                        style={{ width: "25px", margin: "10px" }}
                      />
                    ) : (
                      <img
                        onClick={() => setIsShow(!isShow)}
                        src={hiddenIcon}
                        alt="Show"
                        style={{ width: "25px", margin: "10px" }}
                      />
                    )}
                  </div>
                  {errorPassword && (
                    <i style={{ color: "red", paddingLeft: "10px" }}>
                      Passwords do not match
                    </i>
                  )}
                </div>
                <div className="ms-0 ps-0 BillingAddInputField ">
                  <label className="ProductLabel">
                    Role
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="role"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="mt-2  text-capitalize billing_input_field"
                        // customerInputCss="--customerInputCss"
                        style={error ? { borderColor: "red" } : {}}
                      >
                        <option>None</option>
                        <option value="admin">Admin</option>
                        {adminuser === "superAdmin" ? (
                          <option value="superAdmin">Super Admin</option>
                        ) : (
                          ""
                        )}
                        <option value="limitedAdmin">Limited Admin</option>
                        required
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {adminuser === "superAdmin" ? (
                  <div className="ms-0 ps-0 BillingAddInputField ">
                    <label className="ProductLabel">
                      Status{" "}
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="status"
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <select
                          value={value}
                          onChange={onChange}
                          className="mt-2 billing_input_field"
                          // customerInputCss="--customerInputCss"
                          style={{
                            "text-transform": "capitalize",
                          }}
                        >
                          <option> none</option>
                          <option value="inactive" >
                            In Active
                          </option>
                          <option value="active">Active</option>
                        </select>
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                ) : (
                  " "
                )}
              </div>
              {/*  */}
              <div style={{ padding: "0px 20px 20px", marginBottom: "30px" }}>
                <button
                  type="submit"
                  className="BillingAddSubmitBtn"
                  onClick={() => {
                    errorSumbit();
                  }}
                >
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                  {/* save */}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* </div> */}
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        customerLoginValue={customerLoginValue}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        errorOpen={errorOpen}
        setErrorOpen={setErrorOpen}
        // navigate={"/BillingView"}
        // if (customerLoginValue) {
        //   navigate("/UserRegisterTable");
        // } else {
        //   navigate("/");
        // }
      />
    </>
  );
};
export default CustomerRegister;
