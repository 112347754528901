import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";

export default function AlertModal(props) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    // boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  console.log("props?.val", props?.val, "props.navigate", props.navigate)

  return (
    <div>
      <Modal
        open={props.open || props.UpdateOpen}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{ ...style, width: 400, height: 180, borderRadius: 6 }}
        >
          <h4 className="text-center pt-3">
            {/* UpdateOpen={UpdateOpen} */}
            {/* {console.log('props.open',props.UpdateOpen)} */}
            {props.UpdateOpen ? props.UpdateOpen : props.open}
          </h4>
          <button
            className="modalSubmitBtn mb-3 fs-5"
            onClick={() => {
              props.UpdateOpen
                ? props.setUpdateOpen(false)
                : props.setOpen(false);
              //   props.handleClose()
              navigate(props.navigate, { state: props?.val });

              if (props.customerLoginValue) {
                navigate("/UserRegisterTable");
              }
              if (props.customerLoginValue === null) {
                navigate("/");
              }
            }}
          >
            OK
          </button>
        </Box>
      </Modal>
      <Modal
        open={props.deleteConfirmAlert}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{ ...style, width: 400, height: 180, borderRadius: 6 }}
        >
          <h4 className="text-center pt-3">
            Are you sure you want to delete this?
            {/* {props.UpdateOpen
              ? "Data Updated Successfully"
              : "Data Added Successfully"} */}
          </h4>
          <div>
            <button
              className="modalSubmitBtn mb-3 fs-5 me-4"
              onClick={() => {
                // props.setConfirmAlert(false)
                props.yesOnClick();
                // setDeleteOpen(true)
              }}
            >
              Yes
            </button>
            <button
              className="modalSubmitBtn mb-3 fs-5"
              onClick={() => {
                props.setDeleteConfirmAlert(false);

                // setDeleteOpen(true)
              }}
            >
              No
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={props.deleteSuccessfully}
        onClose={props.handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{ ...style, width: 400, height: 180, borderRadius: 6 }}
        >
          <h4 className="text-center pt-3">
            Deleted Successfully
            {/* {props.UpdateOpen
              ? "Data Updated Successfully"
              : "Data Added Successfully"} */}
          </h4>
          <button
            className="modalSubmitBtn mb-3 fs-5"
            onClick={() => {
              // props.setDeleteConfirmAlert(false)
              props.setDeleteSuccessfully(false);
            }}
          >
            OK
          </button>
        </Box>
      </Modal>
      <Modal
        open={props.errorOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          className="d-flex flex-column justify-content-between align-items-center"
          sx={{ ...style, width: 700, height: 150, borderRadius: 6 }}
        >
          <h5 className="text-center pt-3">
            {props.errorOpen}
            {/* Data Deleted Successfully */}
            {/* {props.UpdateOpen
              ? "Data Updated Successfully"
              : "Data Added Successfully"} */}
          </h5>
          <button
            className="modalSubmitBtn mb-3 fs-5"
            onClick={() => {
              // props.setDeleteConfirmAlert(false)
              props.setErrorOpen(false);
            }}
          >
            OK
          </button>
        </Box>
      </Modal>
    </div>
  );
}
